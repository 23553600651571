<script>


export default {
  data() {
    return {
      formsCategories: [
        {
          title: "Constitution de société",
          folder: "constitutions",
          forms: [
            {filename: "sarl", label: "SARL Société à Responsabilité Limitée"},
            {filename: "sa", label: "SA Société Anonyme"},
            {filename: "sc", label: "SC Société Civile"},
            {filename: "sas", label: "SAS Société par actions simplifiée"},
            {filename: "snc", label: "SNC Société en Nom Collectif"},
          ]
        },
        {
          title: 'Dissolutions - Clôtures',
          folder: "dissolutionsClotures",
          forms: [
            {filename: "dissolution", label: "Dissolution"},
            {filename: "clotureLiquidation", label: "Clôture de liquidation"},
            {filename: "tup", label: "TUP Dissolution avec transmission universelle du patrimoine"},

          ]
        },
        {
          title: "Personnes Physiques",
          folder: "personnesPhysiques",
          forms: [
            {filename: "changementNomPersonneMajeur", label: "Changement de nom personne majeure"},
            {filename: "changementNomAvecEnfantMineur", label: "Changement de nom avec enfant(s) mineur(s)"},
            {filename: "changementNomEnfantMineur", label: "Changement de nom d’enfant(s) mineur(s)"},
            {filename: "changementRegimeMatrimonial", label: "Changement de Régime matrimonial"},
          ]
        },
        {
          title: "Fonds de Commerce",
          folder: "fondsCommerce",
          forms: [
            {filename: "venteFondsCommerce", label: "Vente de fonds de commerce"},
            {filename: "cessionDroitBail", label: "Cession de droit au bail"},
            {filename: "locationGerances", label: "Locations Gérances"},
            {filename: "finLocationGerances", label: "Fin de Locations Gérances"},
          ]
        },
        {
          title: "Modifications",
          folder: "modifications",
          subtitles: [
            {
              title: "Transfert de siège",
              subfolder: "transfertSiege",
              forms: [
                {filename: "sansChangementGreffe", label: "Sans changement de greffe"},
                {filename: "avecChangementGreffeMemeDepartement", label: "Avec changement de greffe même département"},
                {filename: "horsDepartementDepart", label: "Hors département : Départ"},
                {filename: "horsDepartementArrivee", label: "Hors département : Arrivée"},
              ]
            },
            {
              title: "Co-gérant",
              subfolder: "cogerant",
              forms: [
                {filename: "nomination", label: "Nomination"},
                {filename: "demission", label: "Démission"},
              ]
            },
            {
              title: "Commissaires aux comptes",
              subfolder: "commissaireAuxComptes",
              forms: [
                {filename: "nomination", label: "Nomination"},
                {filename: "changement", label: "Changement"},
              ]
            },
            {
              title: "Autres",
              subfolder: "autres",
              forms: [
                { filename: "capital", label: "Capital – Augmentation/réduction" },
                { filename: "prorogation-duree", label: "Prorogation de durée" },
                { filename: "poursuiteActivite", label: "Poursuite d'activité" },
                { filename: "modificationObjetSocial", label: "Modification de l'objet social" },
                { filename: "changementRepresentantLegal", label: "Changement de représentant légal" },
                { filename: "changementDenomination", label: "Changement de dénomination" },
                { filename: "transformationSarlEnSasOuSasu", label: "Transformation de SARL en SAS/SASU" },
              ]
            }
          ]
        }
      ],
    }
  },
  methods: {
    redirectTo(formName) {
      this.$router.push({name: '/form', params: {formName: formName}})
    }
  },
}

</script>


<template>
  <div>
    <div v-for="(formTitle, i) in formsCategories" :key="i">
      <h1>{{ formTitle.title }}</h1>
      <div class="liste-formulaires">
        <ul v-if="formTitle.subtitles" class="list-unstyled">
          <li v-for="(formSubtitle, i) in formTitle.subtitles" :key="i">
            <h6 class="subtitle">{{ formSubtitle.title }}</h6>
            <ul class="list-unstyled mb-3 ms-3">
              <li v-for="(form, y) in formSubtitle.forms" :key="y">
                <a role="button" @click="redirectTo(`/${formTitle.folder}/${formSubtitle.subfolder}/${form.filename}`)">
                  <b-icon-caret-right-fill class="me-1 primary"/>
                  {{ form.label }}</a>
              </li>
            </ul>
          </li>
        </ul>
        <ul v-else class="list-unstyled">
          <li v-for="(form, y) in formTitle.forms" :key="y">
            <a role="button" @click="redirectTo(`/${formTitle.folder}/${form.filename}`)">
              <b-icon-caret-right-fill class="me-1 primary"/>
              {{ form.label }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>


<style scoped>
.subtitle {
  border: 1px solid var(--primary);
  padding: 1rem;
}
</style>